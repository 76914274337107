import {
  findFileByFindType,
  delDraftBox,
  findReportCompletionStatusByCourseId,
  biweeklyReportSubmissionStatus,
} from "./fineApi";
import { findForeignFileByFindId } from "@/views/course-manage/upreport-manage/Upreport-State/startApi";
import { foreignExchangeReportsToEdit } from "@/views/course-manage/upreport-manage/UprePort-Edit/editApi.js";
import { ElMessage } from "element-plus";
import {
  findForeignFileByFindType,
} from "@/views/course-manage/upreport-manage/Upreport-Browser/browserApi";
import dayjs from "dayjs";
import {
  findFileByFileId,
  updTFundFundfiles,
} from "../../course-manage/upreport-manage/UprePort-Edit/editApi";
import { findAllTimrTurn } from "../time-turn/timeApi";
export const getDrafrLists = (data) => {
  let parame = {
    classId: JSON.parse(sessionStorage.getItem("classId")),
    findType: data.pageType,
    filetype: data.reportValue,
    goodType: data.goods ? 1 : 0,
    fileName: data.fileName,
    startTime: data.startTime,
    endTime: data.endTime,
    acid: data.groupValue,
    // lastState: data.lastState,
    // filetype: data.filetype,
    pageSize: data.pageSize,
    page: data.currentPage,
  };

  if (data.financeRadio == "外汇") {
    parame.acId = parame.acid;
    parame.courseId = parame.classId;
    findForeignFileByFindType(parame).then((res) => {
      if (res.data.code === "200") {
        let newRes = res.data.data;
        data.spreadTable = newRes.currencyManagement.list;
        data.total = newRes.currencyManagement.total;
        dealDate(data.spreadTable, ["date"]);
        for (let i in data.spreadTable) {
          data.spreadTable[i].whetherState =
            data.spreadTable[i].whetherToDelay === 0 ? "未延迟" : "延迟";
          data.spreadTable[i].goodTypes =
            data.spreadTable[i].goodType === 0 ? "未评级" : "优秀";
        }
        setTimeout(() => {
          data.loading = false;
          data.disabled = false;
        }, 300);
      }
    });
  } else {
    findFileByFindType(parame).then((res) => {
      if (res.data.code === "200") {
        let newRes = res.data.data;
        data.spreadTable = newRes.reportManagementPageInfo.list;
        data.total = newRes.reportManagementPageInfo.total;
        dealDate(data.spreadTable, ["date"]);
        for (let i in data.spreadTable) {
          data.spreadTable[i].whetherState =
            data.spreadTable[i].whetherToDelay === 0 ? "未延迟" : "延迟";
          data.spreadTable[i].goodTypes =
            data.spreadTable[i].goodType === 0 ? "未评级" : "优秀";
        }
        setTimeout(() => {
          data.loading = false;
          data.disabled = false;
        }, 300);
      }
    });
  }
  data.showHtml = false
};

// 格式化 日期
export const dealDate = (data, dealArr) => {
  let arr = data;
  for (let i = 0; i < arr.length; i++) {
    let res = arr[i];
    for (let j in dealArr) {
      res[dealArr[j]] =
        res[dealArr[j]].toString().slice(0, 4) +
        "-" +
        res[dealArr[j]].toString().slice(4, 6) +
        "-" +
        res[dealArr[j]].toString().slice(6);
    }
  }
  return arr;
};

export const DateChanges = (val, data) => {
  data.pageSize = 10;
  data.currentPage = 1;
  if (val === null) {
    data.startTime = undefined;
    data.endTime = undefined;
  } else {
    data.startTime = dayjs(val[0]).format("YYYYMMDD");
    // data.startTime = Date.parse()/1000
    // data.endTime = Date.parse(val[1])/1000
    data.endTime = dayjs(val[1]).format("YYYYMMDD");
  }
};

// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  getDrafrLists(data);
};

// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  getDrafrLists(data);
};

export const delDrafrFiles = (item, data) => {
  let parame = {
    fieldId: item.fileId,
  };
  delDraftBox(parame).then((res) => {
    if (res.data.code === "200") {
      ElMessage.success(res.data.msg);
      getDrafrLists(data);
    } else {
      ElMessage.error(res.data.msg);
    }
  });
};

export const clickButtons = (val, data, router) => {
  if (val.type === "查看") {
    data.showHtml = true;
    if (data.financeRadio == "外汇") {
      findForeignFileByFindId({ fileId: val.item.fileId }).then((res) => {
        console.log("res--1", res.data);
        if (res.data.code == "200") {
          data.reportHtml = res.data.data.files;
        }
      });
    }
    repHtml(data, val.item.fileId);
  } else {
    checkreport(val.item, data);
  }
};

export const repHtml = (data, id) => {
  findFileByFileId({ fieldIds: [id] }).then((res) => {
    if (res.data.code === "200") {
      let resNew = res.data;
      data.reportHtml = resNew.data.tfundFundfiles[0].files;
    }
  });
};

// 评为热门
export const checkreport = (val, data) => {
  if (data.financeRadio == '外汇') {
    let financeParame = {
      currencyFiles: {
        fileId: val.fileId,
        goodType: 1,
      },
    };
    foreignExchangeReportsToEdit(financeParame).then((res) => {
      if (res.data.code === "200") {
        ElMessage.success(res.data.msg);
        getDrafrLists(data);
      } else {
        ElMessage.error(res.data.msg);
      }
    });
  } else {
    let parame = {
      tfundFundfiles: {
        fileId: val.fileId,
        goodType: 1,
      },
    };
    updTFundFundfiles(parame).then((res) => {
      if (res.data.code === "200") {
        ElMessage.success(res.data.msg);
        getDrafrLists(data);
      } else {
        ElMessage.error(res.data.msg);
      }
    });
  }
};

//  证券获取未完成 报告
export const getNoReLists = (data) => {
  data.loadingEs = true;
  findReportCompletionStatusByCourseId({
    courseId: sessionStorage.getItem("classId"),
    condition: data.condition,
    // courseId:JSON.parse(sessionStorage.getItem('classId'))
  }).then((res) => {
    if (res.data.code === "200") {
      data.spreadTableEs = res.data.data.reportCompletionStatuses;
      data.totalEs = res.data.data.reportCompletionStatuses.length;
      data.disabled = true;
      setTimeout(() => {
        data.loadingEs = false;
        data.disabled = false;
      }, 300);
    }
  });
};

//  外汇获取未完成 报告
export const getForeignNoReLists = (data) => {
  data.loadingEs = true;
  data.disabled = true;
  biweeklyReportSubmissionStatus({
    courseId: sessionStorage.getItem("classId"),
    condition: data.condition,
    // courseId:JSON.parse(sessionStorage.getItem('classId'))
  }).then((res) => {
    if (res.data.code === "200") {
      data.spreadTableEs = res.data.data.reportCompletionStatuses;
      data.totalEs = res.data.data.reportCompletionStatuses.length;
      data.disabled = true;
      setTimeout(() => {
        data.loadingEs = false;
        data.disabled = false;
      }, 300);
    }
  });
};

// 切换条数
export const sizeChangesEs = (val, data) => {
  data.loadingEs = true;
  data.pageSizeEs = val;
  setTimeout(() => {
    data.loadingEs = false;
  }, 300);
};

// 切换页数
export const currentChangesEs = (val, data) => {
  data.loadingEs = true;
  data.currentPageEs = val;
  setTimeout(() => {
    data.loadingEs = false;
  }, 300);
};

// 切换查询周次
export const weekChanges = (val, data) => {
  data.condition = val;
  data.currentPageEs = 1;
  data.pageSizeEs = 10;
  if (data.financeRadio == "外汇") {
    getForeignNoReLists(data);
  } else {
    getNoReLists(data);
  }
  
};
// 查询
export const getUserLists = (data) => {
  let parame = {
    courseId: sessionStorage.getItem("classId"),
    pageSize: 1000,
    page: 1,
  };
  findAllTimrTurn(parame).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      let arr = newRes.pageInfo.list;
      for (let i in arr) {
        data.weekList.push(arr[i].currentWeek);
      }
    }
  });
};
