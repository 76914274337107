import axios from '@/axios/globalaxios'

export const findFileByFindType = data =>{
    return axios({
        url: "/tradelab/tFundFundfiles/findFileByFindType",
        method: 'post',
        data:data
    })
}

export const delDraftBox = data =>{
    return axios({
        url: "/tradelab/tFundFundfiles/delDraftBox",
        method: 'post',
        data:data
    })
}
// 未完成报告
export const findReportCompletionStatusByCourseId = data =>{
    return axios({
        url: "/tradelab/timeRound/findReportCompletionStatusByCourseId",
        method: 'post',
        data:data
    })
}

export const biweeklyReportSubmissionStatus = (data) => {
  return axios({
    url: "/tradelab/currency_files/biweeklyReportSubmissionStatus",
    method: "post",
    data: data,
  });
};


// 查询报告详情
export const findFileByFileId = data =>{
    return axios({
        url: "/tradelab/tFundFundfiles/findFileByFileId ",
        method: 'post',
        data:data
    })
}



